<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userInfo.name || userInfo.email }}
        </p>
        <span class="user-status">{{ userInfo.roleName }}</span>
      </div>
      <b-avatar
        size="40"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <b-dropdown-item
      :to="{ name: 'profile'}"
      link-class="d-flex align-items-center"
      :active="!!(currentRouteName === 'profile')"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>{{ t(`routes.profile`) }}</span>
    </b-dropdown-item>
    <b-dropdown-divider />
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, 
  BAvatar,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  setup() {
    const { t } = useI18nUtils()

    return {
      t,
    }
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    currentRouteName() {
      return this.$route.name
    },
  },
  methods: {
    ...mapActions(['logout']),
  },
}
</script>
