<template>
  <div class="navbar-container main-menu-content">
    <horizontal-nav-menu-items :items="availableMenu" />
  </div>
</template>

<script>
import { appMenuItems } from '@/router'
import store from '@/store'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

export default {
  components: {
    HorizontalNavMenuItems,
  },
  setup() {
    return {
      navMenuItems: appMenuItems,
    }
  },
  computed: {
    availableMenu() {
      // ซ่อนเมนูตาม permission
      const { userPermissions = []} = store.state.user
      const isValidPermission = (permissionKey) => userPermissions.includes(permissionKey) || permissionKey === '0'
      const filterNav = (routes) => {
        
        return routes.filter((route) => {
          if(route.children) {
           route.children = route.children.filter((child) => {
            const childKey = child.key?.split(':')[1] || ''
                return isValidPermission(childKey)
            })
          }
            const permissionKey = route.key?.split(':')[1] || ''
            return route.key? isValidPermission(permissionKey): true
          })
        }
      return filterNav(this.navMenuItems)
      }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
